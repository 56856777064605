<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import StarRating from "vue-star-rating";
// import axios from "axios";


export default {
  page: {
    title: "Riders",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, StarRating },
  data() {
    return {
      // rider: null,
      star: null,
      title: "Rider Details",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Riders",
          href: "JavaScript:void(0)"
        },
        {
          text: "Rider Details",
          active: true
        }
      ],
    }
  },
  mounted() {
    // axios.get('read-user/' + this.$route.params.id, {
    //   params: { userType: 'rider' },
    //   headers: {
    //     'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
    //   }
    // }).then((res) => {
    //   this.rider = res.data.rider
    // }).catch((err) => {
    //   console.log(err)
    // })
      this.$store.dispatch('riders/getRiders')

    if (this.rider.rating === 0) {
      this.star = 0
    } else {
      this.star = this.rider.rating / this.rider.completed_rides
    }

  },

  computed:{
    rider(){
      return this.$store.getters['riders/riderGetter'](this.$route.params.id)
    }
  },


  methods: {
    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },
  }
}

</script>

<template>
  <layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card profile-card">
          <div class="card-body">
            <img alt="200x200" v-if="rider.photo_url !== null" :src="rider.photo_url" data-holder-rendered="true"
              class="img-thumbnail rounded-circle avatar-xl">
            <img alt="200x200" v-if="rider.photo_url === null" src="../../../assets/images/users/dummy-avatar.jpg"
              data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl">
            <div class="profile-text">
              <h5 class="mb-0">{{ rider.first_name }} {{ rider.last_name }}</h5>
              <div class="stars">
                <div class="text-center">
                  <star-rating :rating=star :read-only="true" :star-size="15"></star-rating>
                </div>
              </div>
            </div>
            <!--            <p class="profile-status" :class="{'text-success': `${rider.status.active_status}` === 'active', 'text-danger': `${rider.status.active_status}` === 'inactive'}"><i class="ri-checkbox-blank-circle-fill"></i> {{ rider.status.active_status }}</p>-->

            <!--            <a href="javascript: void(0);" target="_self" class="btn btn-danger profile-button">De-Activate</a>-->
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td class="text-right">{{ rider.first_name }} {{ rider.last_name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Phone Number</th>
                    <td class="text-right">{{ rider.phone_number }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td class="text-right">{{ rider.email_address }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Device</th>
                    <td class="text-right">{{ rider.device_type }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Created At</th>
                    <td class="text-right">{{ convertDate(rider.createdAt) }} {{ convertTime(rider.createdAt) }}</td>
                  </tr>
                  <!--                <tr>-->
                  <!--                  <th scope="row">Timezoned</th>-->
                  <!--                  <td class="text-right">Asia/Karachi</td>-->
                  <!--                </tr>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </layout>
</template>

<style scoped>
.profile-card {
  position: relative;
}

.profile-text {
  position: absolute;
  top: 36%;
  left: 165px;
}

.profile-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.profile-status {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-status i {
  font-size: 12px !important;
}</style>